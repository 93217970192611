<template>
  <div class="container-fluid py-4 add-student ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">عرض بيانات الطالب</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form role="form" class="text-start mt-3">
              <div class="row">
                <div class="table-responsive p-0 overflow student-info">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                       رقم الطالب
                      </th>
                      <td>{{student.number}}</td>
                    </tr>

                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                       اسم الطالب
                      </th>
                      <td>{{student.full_name}}</td>
                    </tr>
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                       رقم الهاتف
                      </th>
                      <td>{{student.mobile}}</td>
                    </tr>
                     <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        الفرقة
                      </th>
                      <td>{{student.grade_name}}</td>
                    </tr>

                  </thead>
                </table>
                </div>
                 <div class="mb-3 col-lg-12 tab-div">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item active" role="presentation" style="width:40% !important">
        <button class="nav-link active btn mb-0 bg-gradient-info   w-96 btn-md null null btn-lg" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">الدورات المسجل فيها </button>
      </li>
      <li class="nav-item" role="presentation" style="width:40% !important" >
        <button class="nav-link btn mb-0 bg-gradient-info  w-96 btn-md null null btn-lg" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">أجهزة الطالب</button>
      </li>
    </ul>
    </div>
              </div>
            </form>
          </div>
        </div>
        <!-- tabs -->
        <div class="row">
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show " id="profile" role="tabpanel" aria-labelledby="profile-tab">
     <!-- devices -->
         <div class="row mt-4">
          <div class="card col-12 my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                   الأجهزة المستخدمة
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0 overflow">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                      class="text-uppercase  font-weight-bolder "
                      >
                        #
                      </th>
                      <th
                        class="text-uppercase   font-weight-bolder"
                      >
                        اسم الجهاز
                      </th>
                      <th
                        class="text-uppercase   font-weight-bolder "
                      >
                        موديل
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                      تاريخ التسجيل في النظام
                      </th>
                        <th
                        class="text-uppercase  font-weight-bolder "
                      >
                        أخر محاولة دخول
                      </th>
                      <th
                        class="text-uppercase  font-weight-bolder "
                      >
                         حالة الجهاز
                      </th>
                       <th
                        class="text-uppercase  font-weight-bolder "
                      >
                         تغيير
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(device,index) in devices" :key="index">
                      <td>
                        <p class="text-s mb-0">{{ device.readable_id}}</p>
                      </td>
                       <td>
                        <p class="text-s mb-0">{{ device.device_info.manufacturer}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{device.device_info.model}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0 ">
                          {{ device.created_at }}
                        </p>
                      </td>
                       <td>
                        <p class="text-s mb-0 ">
                          {{ device.last_try??'-' }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{
                           device.active?'مفتوح':'مقفول '
                          }}
                        </p>
                        </td>
                        <td>
                          &ensp;<a v-if='device.active' href="javascript:void(0);" @click="toggleDevice(device.id)"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm bg-gradient-success"><i class="fas fa-lock-open"></i></span></a>
                          &ensp;<a v-else href="javascript:void(0);" @click="toggleDevice(device.id)"><i class="mdi mdi-square-edit-outline"></i><span class="badge badge-sm bg-gradient-danger"><i class="fas fa-lock"></i></span></a>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
  </div>
  <div class="tab-pane fade show active" id="contact" role="tabpanel" aria-labelledby="contact-tab">
          <!-- current registration -->
        <div class="row mt-4">
          <div class="col-12">
            <div class="card my-4">
              <div
                class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
              >
                <div
                  class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                >
                  <h6 class="text-white text-capitalize ps-3">
                    الدورات المسجل فيها الطالب
                  </h6>
                </div>
              </div>
              <div class="card-body px-0 pb-2">
                <div class="table-responsive p-0 ">
                  <table class="table align-items-center mb-0 student-enroll-c">
                    <thead>
                      <tr>
                         <th
                          class="text-uppercase  font-weight-bolder "
                        >
                          الرقم
                        </th>
                        <th
                          class="text-uppercase  font-weight-bolder "
                        >
                          اسم الدورة
                        </th>
                         <th
                          class="text-uppercase  font-weight-bolder "
                        >
                           الحضور
                        </th>
                         <th
                          class="text-uppercase  text-center font-weight-bolder "
                        >
                           الفيديوهات
                        </th>
                         <th
                          class="text-uppercase   font-weight-bolder "
                        >
                           حد المشاهدات
                        </th>
                           <th
                          class="text-uppercase   font-weight-bolder "
                        >
                            زيادة المشاهدات
                        </th>
                           <th
                          class="text-uppercase  font-weight-bolder "
                        >
                               مشاهدات الطالب
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(course,index) in myCourses" :key="index">
                        <td >
                          <p class="text-s mb-0">{{ course.number }}</p>
                        </td>
                        <td style=" width: 20%; white-space: normal;" class="wrap-text">
                          <p class="text-s mb-0">{{ course.name }}</p>
                        </td>
                         <td class="text-center">
                          <p class="text-s mb-0">{{ course.lectures_attended_count }}</p>
                        </td>
                        <td style="width: 66%;" class="text-center" >
                           <multiselect style="margin-top:0 !important" v-if="course.type"
                        class='required form-control margin0'
                        id='video'
                        v-model=" course.selectedvideo"
                        :options='myCourses[index].videos'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الفيديو"
                        open-direction="top"
                        label='name'
                        @select='getWatchLimit($event,index)'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                      <p v-else>الدورة أوفلاين</p>
                        </td>
                         <td style="width:8%" class="text-center">
                           <div class=" col-lg-8"  v-if="course.type">
                      <vmd-input
                      class="text-center"
                        id=""
                       type="text"
                        disabled
                        v-model='course.watchLimit'
                        />
                        </div>
                         <p v-else>-</p>
                        </td>
                        <td style="width:8%" class="text-center"> <div class="text-center col-lg-2 " v-if="course.type">
                  <vmd-button
                    class="text-center "
                    variant="gradient"
                    @click="increaseWatchLimit($event,index)"
                    color="info"
                    ><span> +</span></vmd-button
                  >
                    </div>
                     <p v-else>-</p>
                    </td>
                        <td style="" class="text-center" >
                         <ul class="" v-if="course.type && courseWatches">
                          <li class="watches-time" v-for ="(item,index) in course.watchTimes" :key="item.id">
                          مشاهدة #{{index+1}} - {{item.pivot.watched_at}}</li>
                          </ul>
                      <p v-else> - </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
export default {
  name: 'ShowStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      student: {},
      courses: [],
      devices: [],
      myCourses: [],
      receipts: [],
      selectedVideo: '',
      courseWatches: false,
      totalPayed: 0,
      clicksCount: 0,
      walletPayments: [],
      ReceiptPayments: [],
      showModal: false
    }
  },
  created () {
    const current = this
    this.$http
      .get(`${this.$hostUrl}students/${this.$route.params.id}`)
      .then((response) => {
        this.student = response.data.data
        this.devices = this.student.device
        // this.myCourses = this.student.my_courses
        const receipts = this.student.payment_history
        receipts.forEach(function (receipt) {
          if (receipt.number === null && (receipt.offer || receipt.course)) {
            current.receipts.push(receipt)
            receipt.subject_name = receipt.payable.subject ? receipt.payable.subject.name : ''
            if (
              receipt.transaction_type.id ===
              '78d9a393-9b84-4372-bb38-5d8b42eccd29'
            ) {
              current.totalPayed -= receipt.amount
            } else {
              current.totalPayed += receipt.amount
            }
          }
        })
        current.receipts.sort(
          (p1, p2) => (Date.parse(p1.created_at) > Date.parse(p2.created_at)) ? 1 : (Date.parse(p1.created_at) < Date.parse(p2.created_at)) ? -1 : 0)
      })
    current.$http
      .get(`${current.$hostUrl}students/${current.$route.params.id}/videos`)
      .then((response) => {
        this.myCourses = response.data.data
      })
  },
  methods: {
    getWatchLimit ($event, index) {
      this.myCourses[index].watchLimit = $event.student_watch_limit
      this.myCourses[index].studentWatchLimit = $event.student_repeats
      this.myCourses[index].videoId = $event.id
      this.myCourses[index].watchTimes = $event.student_watches
      const array = this.myCourses[index].watchTimes
      array.sort((a, b) => new Date(a.pivot.watched_at).getTime() - new Date(b.pivot.watched_at).getTime())
      console.log(array, 'array')
      console.log(this.myCourses[index].watchTimes, 'watches')
      if (this.myCourses[index].watchTimes.length) {
        this.courseWatches = true
      } else {
        this.courseWatches = false
      }
    },
    async increaseWatchLimit ($event, index, e) {
      if (this.myCourses[index].watchLimit === 0 || this.myCourses[index].watchLimit === null) {
        swal('الدورة مجانية')
      }
      this.myCourses[index].watchLimit++
      const formData = new FormData()
      formData.append('video_id', this.myCourses[index].videoId)
      formData.append('watch_limit', this.myCourses[index].watchLimit)
      this.errors = await this.postRequest(formData, `${this.$hostUrl}student/${this.$route.params.id}/increase-limit`, '')
    },
    async toggleDevice (id) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم  تغيير حالة الجهاز !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(
              `${this.$hostUrl}device/${id}/toggle/student/${this.$route.params.id}`
            )
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                // refresh data
                this.$http
                  .get(`${this.$hostUrl}students/${this.$route.params.id}`)
                  .then((response) => {
                    this.student = response.data.data
                    this.devices = this.student.device
                  })
              }
            }).catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    getStudentPayments () {
      this.$http
        .get(`${this.$hostUrl}student/${this.$route.params.id}/payments`)
        .then((response) => {
          this.walletPayments = response.data.data.wallet_history
        })
    },
    getStudentReceipts () {
      this.$http
        .get(`${this.$hostUrl}student/${this.$route.params.id}/payments`)
        .then((response) => {
          this.ReceiptPayments = response.data.data.payments
        })
    }
  }
}

</script>
<style>
.margin0{
  margin-top:0 !important
}
.tab-div{
  margin-top: 50px !important;
}
.nav-tabs{
  border-bottom:unset !important
}
.tab-div li{
  width:33%
}
.tab-div .btn-info{
  color:#fff
}
.nav-tabs .nav-link.active,
 .nav-tabs .nav-item.show .nav-link{
  color:#fff !important;
  background-image: linear-gradient(195deg, #49a3f1 0%, #1a73e8 100%) !important;
 }
.nav-tabs .nav-link{
  color:#222 !important;
  background:#f8f9fa !important
}
.watches-time{
  font-weight: bold;
  font-size: 13px !important;
}
.paid-back{
 background: #baf2c5a1 !important;
}
.refund-back{
  background: #fb060669 !important;
}
.student-info table{
  width:75%;
  text-align: right;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
   rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.student-info td{
  background: #fff !important;
}
.close-btn{
  width:14%;
  margin-top:30px !important
}
#ch-receipt{
  padding-top: 20px;
  padding-right: 20px;
}
.student-enroll-c td{
  white-space: normal;
}
.w-96{
 width: 96% !important
}
</style>
